<template>
  <v-container class="white px-0" fluid>
    <v-card flat>
      <profile-form
        :item="item"
        @save="save"
        :profile="true"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ProfileForm from "./ProfileForm";

export default {
  name: "Profile",
  components: { ProfileForm },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.item = this.$getUserInfo();
  },
  methods: {
    ...mapActions("auth", ["saveProfile"]),

    save(params) {
      const { item } = params;
      this.saveProfile(item).then(() => {
        this.$$router.back();
      });
    },
  },
};
</script>
